import React from 'react'
import Navbar from './Navbar'

const Footer =()=>{
	return <footer>
	<div>
	{/*<Navbar />*/}
	<p>© Мария Ковалева, 2014-{new Date().getFullYear()}</p>
	<p>Сайт сделан в <a href='https://barcelonacodeschool.com'>BCS</a></p>
	</div>
	</footer>
}

export default Footer