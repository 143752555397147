import React from 'react'

const Navbar =()=>{
	return <ul className='navbar'>
	<li><a href='#services'>Услуги</a></li>
	<li><a href='#feedback'>Отзывы</a></li>
	<li><a href='#booking'>Записаться</a></li>
	<li><a href='#contacts'>Контакты</a></li>
	<li><a href='#english'>English</a></li>
{/*	<li><a href='http://inyourhead.ru/wp-content/uploads/inyourhead_dogovor.pdf'>Договор на услуги</a></li>*/}
	</ul>
}

export default Navbar