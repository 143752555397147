import React from 'react'
import ScriptTag from 'react-script-tag'
import Instagram from '../assets/instagram.jpeg'
import Whatsapp from '../assets/whatsapp.png'

const Contacts =()=>{
	return <section className='contacts' id='contacts'>
	<h1>Контакты</h1>
	<p>как со мной связаться</p>
	<p>
	<a href='tel:+79777711112'>+7 977 771 11 12</a> | <a href='mailto:maria@inyourhead.ru'>maria@inyourhead.ru</a> | 
	<a href='https://instagram.com/maria_kovaleva_mindfulness'><img className='social' src={Instagram}/></a> | 
	<a href='https://wa.me/+79777711112'><img className='social' src ={Whatsapp}/></a>
	</p>


	
{/*	<div className='grid2'>
	<div>
	<p>Можете воспользоваться этой формой обратной связи</p>
	<form>
	<input />
	<input />
	<textArea />
	<button>Отправить</button>
	</form>
	</div>
	<div>
	<ScriptTag type="text/javascript" src="https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A634608078110382f538421f0545c30812a2d899b08cd79471f4e514808c2b325&amp;width=100%&amp;height=400&amp;lang=ru_RU&amp;scroll=true" async="" type="text/javascript" charset="utf-8" />

	</div>
	</div>*/}
	<hr/>
	</section>
}

export default Contacts



