import React from 'react'


const Videos =()=>{
	let vidz = [
	"https://www.youtube.com/embed/9mWLfpeFG4M?rel=0&modestbranding=1",
	"https://www.youtube.com/embed/FoxhRbLpCX8?rel=0&modestbranding=1",
	"https://www.youtube.com/embed/tApvYfcVY64?rel=0&modestbranding=1",
	"https://www.youtube.com/embed/KQp7LR89eto?rel=0&modestbranding=1",
	"https://www.youtube.com/embed/gXPeWvBn6co?rel=0&modestbranding=1"
	]

	let renderVidz =(ls)=>{
		const shuffleArray = ls => ls
		.map(a => [Math.random(), a])
		.sort((a, b) => a[0] - b[0])
		.map(a => a[1]);
		
		ls = shuffleArray(ls)

		return ls.map((v,i)=>(<div key={i} className='video-container'>
			<iframe
			width="560"
			height="315"
			src={v}
			frameBorder="0"
			allowFullScreen
			></iframe>
			</div>))
	}

	return <section className='videos' id='videos'>
	<h1>Приятно познакомиться</h1>
	<article>
	{renderVidz(vidz)}
	</article>
	<hr/>
	</section>
}

export default Videos
