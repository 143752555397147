import React from 'react'
import Instagram from '../assets/instagram.jpeg'
import Whatsapp from '../assets/whatsapp.png'

const English =()=>{
return <section className='english' id='english'>
<h1>Maria Kovaleva</h1>
<p>English-speaking licensed psychotherapist</p>
<p>Experienced psychotherapist accustomed to treating expats.</p>
<p>Humanistic approach. Panculturally sensitive.</p>
<p>Personalised counselling and therapy to deal with emotional distress, relationship matters, problem solving, goal achievement:</p>
<ul>
<li>Family issues</li>
<li>Anxiety, loneliness</li>
<li>Difficult teenagers, problems with kids</li>
<li>Existential questions</li>
<li>Cross-cultural adjustment</li>
<li>Coaching</li>
</ul>
	<p>
	<a href='tel:+79777711112'>+7 977 771 11 12</a> | <a href='mailto:maria@inyourhead.ru'>maria@inyourhead.ru</a> | 
	<a href='https://instagram.com/maria_kovaleva_mindfulness'><img className='social' src={Instagram}/></a> | 
	<a href='https://wa.me/+79777711112'><img className='social' src ={Whatsapp}/></a>
	</p>
</section>
}

export default English