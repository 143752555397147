import React, {useState} from 'react'
import { Accordion, Icon } from 'semantic-ui-react'

const Services =()=> {
	const [state,setState] = useState({ activeIndex: 8 })

	let handleClick = (e, titleProps) => {
		console.log('click')
		const { index } = titleProps
		const { activeIndex } = state
		const newIndex = activeIndex === index ? -1 : index

		setState({activeIndex: newIndex })
	}


	const { activeIndex } = state

	return (
		<section className='services' id='services'>
		<h1>Услуги</h1>
		<Accordion fluid styled>


		<Accordion.Title
		active={activeIndex === 8}
		index={8}
		onClick={handleClick}
		>
		<Icon name='dropdown' />
		<h2>Скайп-консультации</h2>
		</Accordion.Title>
		<Accordion.Content active={activeIndex === 8}>

		<p>Консультации по скайпу подходят для тех, кому удобно получать вышеперечисленные услуги, не выходя из дома/офиса. Напишите мне на электронную почту maria@inyourhead.ru и мы договоримся о сессии.</p>
		</Accordion.Content>



		<Accordion.Title
		active={activeIndex === 2}
		index={2}
		onClick={handleClick}
		>
		<Icon name='dropdown' />
		<h2>Индивидуальное консультирование</h2>
		</Accordion.Title>
		<Accordion.Content active={activeIndex === 2}>

		<ul>
		<li>Стать уверенным в себе человеком</li>
		<li>Повысить самооценку</li>
		<li>Вызывать уважение у окружающих</li>
		<li>Разобраться в отношениях с родителями</li>
		<li>Избавиться от лишнего веса (или набрать вес)</li>
		<li>Стать счастливым</li>
		<li>Освободиться от проблем и негативных чувств, мучающих вас</li>
		<li>Стать внутренне свободным</li>
		<li>Обрести счастье и душевный покой</li>
		<li>Наладить отношения в своей личной жизни</li>
		<li>Преодолеть возрастные и прочие кризисы</li>
		<li>Избавиться от комплексов и недостатков</li>
		<li>Избавиться от страхов, тревог</li>
		<li>Решить проблемы, возникающие на работе</li>
		<li>Понять себя</li>
		</ul>

		</Accordion.Content>






		<Accordion.Title
		active={activeIndex === 3}
		index={3}
		onClick={handleClick}
		>
		<Icon name='dropdown' />
		<h2>Семейное консультирование (индивидуальное консультирование по поводу отношений в паре, консультирование пар)</h2>
		</Accordion.Title>
		<Accordion.Content active={activeIndex === 3}>
		<ul>
		<li>Решить проблемы с партнером</li>
		<li>Наладить отношения в семье</li>
		<li>Разобраться в себе</li>
		<li>Понять, что вы хотите в отношениях</li>
		<li>Понять ваш семейный сценарий и переработать его</li>
		</ul>

		</Accordion.Content>









		<Accordion.Title
		active={activeIndex === 5}
		index={5}
		onClick={handleClick}
		>
		<Icon name='dropdown' />
		<h2>Детская психология</h2>
		</Accordion.Title>
		<Accordion.Content active={activeIndex === 5}>

		<p>Наладить отношения с ребенком</p>
		<p>Помочь ребенку решить его проблемы</p>
		<p>Справиться с возрастными кризисами ребенка (от капризов, истерик до проблем подросткового возраста)</p>
		<p>Разобраться со сложными ситуациями</p>
		<p>Справиться с разводом</p>
		</Accordion.Content>




		<Accordion.Title
		active={activeIndex === 1}
		index={1}
		onClick={handleClick}
		>
		<Icon name='dropdown' />
		<h2>Котерапия в семейном консультировании</h2>
		</Accordion.Title>
		<Accordion.Content active={activeIndex === 1}>
		<p>Это одновременное участие в работе с парой двух психологов - мужчины и женщины.</p>
		<p></p>
		<p>Котерапия помогает значительно увеличить эффективность консультирования, потому что взгляд на ситуацию в семье двух профессионалов, тем более разного пола, более объективный.</p>
		<p></p>
		<p>Клиентской паре проще прийти к двум психологам, так как мужчина может комфортнее себя чувствовать, зная, что на сеансе будет также и психолог мужчина, которому он изначально может больше доверять, чем женщине-психологу.</p>
		<p></p>
		<p>Котерапия помогает смоделировать ситуацию клиентов при помощи пары психологов, что позволяет эффективно работать с ней.</p>
		<p></p>
		<p>Клиенты имеют возможность наблюдать свою ситуацию со стороны, что ускоряет течение психотерапии.</p>
		<p></p>
		<p>К преимуществам котерапии относится также то, что психологи помогают друг другу, обращая внимание на разные аспекты взаимоотношений в семье. </p>

		</Accordion.Content>



		<Accordion.Title
		active={activeIndex === 6}
		index={6}
		onClick={handleClick}
		>
		<Icon name='dropdown' />
		<h2>Коучинг</h2>
		</Accordion.Title>
		<Accordion.Content active={activeIndex === 6}>

		<ul>
		<li>Определиться с тем, чего бы вам хотелось в личной жизни, профессиональной сфере, творчестве</li>
		<li>Иметь цель в чем-либо, но можете ее определить</li>
		<li>Достичь цели, но не знаете, как это сделать</li>
		<li>Заниматься чем-то, но не знаете, чем именно</li>
		</ul>
		</Accordion.Content>


		<Accordion.Title
		active={activeIndex === 7}
		index={7}
		onClick={handleClick}
		>
		<Icon name='dropdown' />
		<h2>Групповая психотерапия</h2>
		</Accordion.Title>
		<Accordion.Content active={activeIndex === 7}>

		<p>Вам ко мне, если вы хотите:</p>
		<ul>
		<li>Решить возникшие проблемы в группе</li>
		<li>Увидеть проблемную ситуация со стороны глазами участников группы</li>
		<li>Получить поддержку не только от психолога, но и от участников группы</li>
		<li>Проработать волнующие ситуации в условиях мини-социума, что поможет в реальной жизни</li>
		</ul>
		</Accordion.Content>





		<Accordion.Title
		active={activeIndex === 0}
		index={0}
		onClick={handleClick}
		>
		<Icon name='dropdown' />
		<h2>Центр “Осознанность” Марии Ковалевой</h2>

		</Accordion.Title>
		<Accordion.Content active={activeIndex === 0}>
		<p>Центр “Осознанность” Марии Ковалевой проводит разнообразные занятия в области практики осознанности и осознанного образа жизни. Занятия проходят очно и онлайн, на русском и английском языках.</p>

		<p>Цель Центра "Осознанность" - создание платформы для распространения знаний и обучение осознанному образу жизни. Мы убеждены, что практика осознанности может помочь улучшить качество жизни.</p>

		<p>Информация о центре и занятиях.</p>
		</Accordion.Content>

		</Accordion>
		<hr/>
		</section>
		)

}

export default Services

