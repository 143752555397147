import React from 'react';
import './App.css';
import Header from './components/Header'
import Navbar from './components/Navbar'
import Services from './components/Services'
import Footer from './components/Footer'
import English from './components/English'
import Feedback from './components/Feedback'
import Booking from './components/Booking'
import Videos from './components/Videos'
import Contacts from './components/Contacts'
import Maria from './assets/maria_kovaleva.jpeg'
// import {Helmet} from 'react-helmet';

function App() {
  return (
    <div className="App">

    <header className="App-header">
    <Header />
    <Navbar />
    </header>
    <main>
    <section className='aboutme'>
    <img src={Maria} alt="Психолог Мария Ковалева"/>
    <article>
    <p>Мария Ковалева, психолог, кандидат наук, психологическое - второе высшее образование (Московский городской институт современных психологических технологий, 2009 г), работает в рамках когнитивной-поведенческой психотерапии, а также других направлений (гештальт, психодрама, пр.). </p>
    <p>Ведёт частную практику на русском и английском языках как очно, так и онлайн.</p>
    <p>Основатель Центра Осознанность Марии Ковалевой, проводит занятия по практике осознанности, также очно и онлайн. Проходила обучение в Англии и Америке. Ведущая психологических занятий и тренингов, детских занятий (психологический Детский Книжный клуб, тренинги по Личной безопасности, занятия в летнем лагере малого мехмата МГУ).</p>
    </article>
    <hr/>
    </section>
    <Services />
    <Feedback />
    <Videos />
    {/*<Booking />*/}
    <Contacts />
    <English />
    </main>
    <Footer/>
    </div>
    );
}

export default App;
