import React from 'react'
// import Ror from '../assets/Rorschach_Inkblot_002.jpg'

const Header =()=>{
	return <div className='headerimg'>
	<div>
	<h1>Психолог Мария Ковалева</h1>
	<h2>Консультации и коучинг</h2>
	{/*<h3>Москва | Барселона | Онлайн</h3>*/}
	</div>
	</div>
}

export default Header

// styles={{backgroundImage: `url(${Ror})` }}