import React,{useState} from 'react'
import { Menu } from 'semantic-ui-react'

const Feedback =()=>{

	const [state, setState] = useState({ activeItem: 'Евгения' })

	let handleItemClick = (e, { name }) => setState({ activeItem: name })


	const { activeItem } = state
	console.log(state.activeItem)
	return (
		<section className='feedback' id='feedback'>
		<h1>Отзывы</h1>
	{/*<Menu tabular>*/}
	<div className='feedbackContainer'>
	<Menu text vertical>
	<Menu.Item
	name='Валерий'
	active={activeItem === 'Валерий'}
	onClick={handleItemClick}
	/>
	<Menu.Item
	name='Аня'
	active={activeItem === 'Аня'}
	onClick={handleItemClick}
	/>
	<Menu.Item
	name='Евгения'
	active={activeItem === 'Евгения'}
	onClick={handleItemClick}
	/>
	<Menu.Item
	name='Екатерина'
	active={activeItem === 'Екатерина'}
	onClick={handleItemClick}
	/>
	<Menu.Item
	name='Ирина'
	active={activeItem === 'Ирина'}
	onClick={handleItemClick}
	/>
	<Menu.Item
	name='Мойш'
	active={activeItem === 'Мойш'}
	onClick={handleItemClick}
	/>
	<Menu.Item
	name='Георгий'
	active={activeItem === 'Георгий'}
	onClick={handleItemClick}
	/>
	<Menu.Item
	name='Айси'
	active={activeItem === 'Айси'}
	onClick={handleItemClick}
	/>

	<Menu.Item
	name='Женя'
	active={activeItem === 'Женя'}
	onClick={handleItemClick}
	/>

	<Menu.Item
	name='Анна'
	active={activeItem === 'Анна'}
	onClick={handleItemClick}
	/>

	<Menu.Item
	name='Ася'
	active={activeItem === 'Ася'}
	onClick={handleItemClick}
	/>

	<Menu.Item
	name='Саша'
	active={activeItem === 'Саша'}
	onClick={handleItemClick}
	/>
	</Menu>
	{
		state.activeItem === 'Валерий' ? 
		<div className="ui bottom attached tab segment active" data-tab="first">
		<p>Я обратился за помощью к Марии, находясь в долгих созависимых отношениях, и абсолютно не понимая кто я, чего я хочу от жизни, от себя и от отношений. В моем состоянии я уже не особо верил во что-то, но почему-то поверил в психологию, как в последнюю надежду на изменение своей жизни.</p>
		<p>В процессе нашей работы Мария смогла заслужить мое абсолютное доверие, как в человеческом, так и в профессиональном плане. Я видел, что она знает что делать, и это, конечно, придавало и мне уверенности в том, что мы во всём разберемся. Каждая сессия была продолжением предыдущих, и я ощущал, что мы не просто общаемся, а мы действительно работаем. А через какое-то время я начал замечать изменения в своей жизни.</p> 
		<p>Терапия никогда не была чем-то приятным и в удовольствие, было сложно, болезненно и еще раз сложно. Но я всегда ощущал, что я в надёжных руках, чувствовал заботу и поддержку. Было множество инсайтов, которые мягко сказать меня удивляли, но познакомиться с собой - это лучшее, что можно для себя сделать. </p>
		<p>Спустя два года терапии, я не чувствую себя другим человеком, я наконец чувствую себя самим собой. И всё, через что мне пришлось пройти, стоило того. Я безумно благодарен Марии за то, что она помогла мне вернуться к жизни и понять себя. Два года назад я и представить себе не мог, что так вообще бывает!</p>
		</div> :
		state.activeItem === 'Евгения' ? 
		<div className="ui bottom attached tab segment active" data-tab="first">
		<p>С Марией начали общение в очень сложный для меня период. Тяжёлые созависимые отношения, отсутствие уверенности в себе и непонимание как с этим быть делали каждый день очень грустным и неопределённым.</p> 

		<p>Через какое-то время я стала замечать, что после общения с Марией у меня снова появляются силы, хочется снова делать что-то для себя, время наедине с собой стало снова приносить удовольствие.</p> 

		<p>Мария помогла по-новому посмотреть на многие вещи, прошёл год с начала нашего общения, и я очень благодарна Марии за то, как замечательно я себя чувствую и за все позитивные изменения в моей жизни: вышла из тягостных отношений; стала снова радостно смотреть на жизнь и планировать ее так, как мне хочется; определилась с тем, каким образом хочется дальше строить карьеру - то, что давно хотела и давно откладывала.</p>

		<p>Это была самая продуктивная и лучшая работа над собой, которая только могла быть :)</p>
	</div> :
	state.activeItem === 'Екатерина' ? 
	<div className="ui bottom attached tab segment active" data-tab="first">
	<p>К Марии я пришла с суицидальными мыслями, с бесконечными слезами и усталостью от отношений, из которых я не могла выйти. Оказалось, что это про созависимость.</p>

	<p>Когда мы впервые созвонились по видеосвязи, сразу захотелось довериться, и я доверилась, за что по сей день очень благодарна.</p>
	<p>Буквально за несколько консультаций я словно обрела внутреннюю силу, пересмотрела текущие отношения и научилась говорить “Нет” тому, что мне не подходит, опираясь на свои чувства.</p>

	<p>С расставания прошел месяц, и весь этот месяц я чувствую, что все, что мне нужно, есть во мне самой. И как же все-таки прекрасно жить!</p>
	</div> :
	state.activeItem === 'Ирина' ? 
	<div className="ui bottom attached tab segment active" data-tab="first">
	<p>Контакт Марии я узнала у своего знакомого, который часто упоминал о том, как улучшилась его жизнь с приходом в неё психотерапии. Я тогда задумывалась о терапии, через 2 года созрела и обратилась за услугами.</p>

	<p>Мария - профессионал своего дела, соблюдает максимальную этику в работе (не оценивает действия/мысли клиента, не даёт «советов», действует очень аккуратно и бережно). Работа ведётся в очень комфортном темпе. Всегда были домашние задания, полезные. Часто Мария объясняла, зачем даёт ту или иную технику/упражнение, какая от этого может быть польза. После каждой сессии был конкретный результат, приходили ценные осознания. За 5 месяцев еженедельных сессий разобрали «острые» вещи, сейчас перешли на поддерживающий формат с большим интервалом между сессиями. Качеством услуг Марии я очень довольна, рекомендую всем, кто в поисках хорошего грамотного специалиста!</p>
	</div> :
	state.activeItem === 'Мойш' ? 
	<div className="ui bottom attached tab segment active" data-tab="first">
	<p>Я занимаюсь с Марией в 2016 году. Это пожалуй был самый сложный год моей жизни, что наконец заставило меня серьезно засесть с психологом. С тех пор я не перестаю удивляться всем позитивным метаморфозам в моей жизни, и если я должен назвать один фактор всех перемен это именно работа с Марией. Ее умение вести клиента к моменту когда он/она сам видит корень проблемы выделяет ее от многих терапевтов с которыми я работал раньше. Бесконечно ей благодарен за все чему она меня научила за это время.</p>
	</div> :
	state.activeItem === 'Георгий' ? 
	<div className="ui bottom attached tab segment active" data-tab="first">
	<p>У меня уже был некоторый опыт работы с психологами и довольно позитивный. Поэтому я с удовольствием согласился попробовать котерапию и я остался очень доволен. Мне кажется, этот метод даже более эффективен, чем обычные сеансы один на один. По-моему, работая с одним психологом со временем ты невольно подстраиваешься под него тоже, ловишь его волну и вы начинаете петь одну песню. Это происходит бессознательно и невольно, так как не только они изучают нас во время сеансов, но и мы точно также узнаем их с каждым разом все лучше и лучше. </p>

	<p>Когда же перед тобой сидит два человека с разным мировоззрением и подходом к клиенту эффективность повышается в разы. Все твои вопросы и проблемы рассматриваются под разными углами и решение возникает гораздо быстрее. Иными словами, скорость обработки информации увеличивается в два раза минимум. На мой взгляд подход Марии хоть и невероятно эффективен, но отличается большой деликатностью. Денис же наоборот берет с места в карьер и таким образом они прекрасно дополняют друг друга. Я считаю эти сеансы одними из лучших в моей жизни.</p>
	</div> : 
	state.activeItem === 'Айси' ? 
	<div className="ui bottom attached tab segment active" data-tab="second">
	<p>Попала к Марии в очень плохом эмоциональном состоянии: переживала кризис семейных отношений, испытывала внутренний конфликт.</p>

	<p>Хочу выразить огромную благодарность за чуткость и отзывчивость. Мария не только профессионал, но еще и прекрасный человек. Мне всегда очень трудно раскрыться перед незнакомыми, но буквально на первом приеме я почувствовала дружескую атмосферу и мне было легко говорить о своих проблемах. Как будто я знала Марию уже давно.</p>

	<p>Мария помогла мне посмотреть на себя, свои поступки и в целом на свою ситуацию с другой стороны. </p>

	<p>Прекрасно, что в трудную минуту можно обратиться к психологу. Можно, конечно, бесконечно плакаться в жилетку подругам, но намного конструктивнее будет пойти к профессионалу, который поможет выйти из кризисной ситуации и вернуться к нормальному стабильному состоянию.</p>

	<p>Если мне в будущем потребуется помощь психолога, то я без сомнения снова обращусь к Марии.</p>
	</div> : 
	state.activeItem === 'Женя' ? 
	<div className="ui bottom attached tab segment active" data-tab="third">
	<p>Мы встретились с Марией не в самое лучшее время своей жизни, и считаю это неслучайным. На самой первой встрече я почувствовала невероятную теплоту и душевность, исходящую от нее: как будто я знаю ее уже много лет и она мой давний близкий друг. В конце этой встречи мне захотелось ее обнять, что я и сделала! Мария заботливо помогла мне справиться с моими текущими проблемами, обрести и понять себя.</p>

	<p>Мария — настоящий профессионал своего дела и просто чудесный человечек. Помимо того, что она всегда готова была выслушать и направить мои же размышления в нужное русло, она использовала интересные методики в работе, которые также помогали глубже разобраться в проблеме.</p>

	<p>Мне хотелось поделиться "своей находкой" с самыми близкими и дорогими мне людьми, которые в свою очередь тоже полюбили Марию.</p>
	</div> :
	state.activeItem === 'Анна' ? 
	<div className="ui bottom attached tab segment active" data-tab="fourth">
	<p>До Марии я общалась со многими психологами, но все было бесполезно. Все мои проблемы оставались со мной. К Марии попала случайно, по совету подруги, и она мне сразу очень понравилась. И дело даже не в том, что она очень приятный, добрый и замечательный человек. Она вроде бы не делат ничего особенного, просто сидит, слушает, делает какие-то пометки, а потом вдруг что-нибудь тебя переспросит... И этот один ее вопрос сразу переворачивает всю твою картину мира. Ты сразу видишь свои проблемы с совершенно другой стороны, и они уже не кажутся тебе неразрешимыми. Спасибо, что научили меня смотреть на свою жизнь совсем по-другому.</p>
	</div> :
	state.activeItem === 'Ася' ? 
	<div className="ui bottom attached tab segment active" data-tab="fith">
	<p>Бывают такие знакомства, когда с первой минуты возникает уверенность, что ты знаешь человека очень давно, и возникает необъяснимое полное доверие. Вот так было с Марией.</p>

	<p>Когда я увидела ее впервые, мгновенно появилось чувство, что все, наконец-то я дома. Мария — очень внимательный, очень тонкий человек, и, главное, невероятно сочувствующий. За все время наших встреч, у меня ни на секунду не возникло ощущения, что меня или мои переживания «препарирует» человек с холодным и расчетливым разумом. Всегда я видела перед собой очень доброго и чуткого друга, которому можно полностью довериться. И каким-то чудесным образом, проблемы решались, находились ответы на вопрос — или во время самих встреч или через короткое время после них.</p>

	<p>Раньше я периодически интересовалась тем, как же на самом деле «работает» психотерапия, но после бесед и работы с Марией меня совсем перестало это интересовать. Потому что был Результат.</p>

	<p>Причем почти сразу же, после первой встречи. Первой проблемой, с которой я к ней обратилась, был неконтролируемый страх перед общением, перед большим скоплением людей, я панически боялась спускаться в метро... В момент, когда я встретилась с Марией, эти проблемы продолжались около трех лет. А сейчас я даже не помню, что когда-то могла испытывать страх!</p>

	<p>Я встречаюсь с Марией не регулярно, а когда появляется необходимость. И это тоже очень важно: что у меня нет привязанности к этим сессиям. После нашей совместной работы я стала очень точно себя «слышать», и обращаться за помощью только тогда, когда мне это действительно нужно.</p>

	<p>Наши сессии помогли мне действительно отпустить старые обиды, понять свою маму и близких, увидеть свою роль в семье, принять себя и свои заморочки, и, в конце концов, стать ближе к себе настоящей.</p>

	<p>Вот, чему я сейчас радуюсь, вот мои результаты: теперь мы стали хорошими приятелями с моим бывшим мужем, которого я не могла простить несколько лет, а теперь я планирую принять его приглашение и навестить его; я могу ездить на метро, когда мне нужно; мне снова стало легко поддерживать разговор с незнакомыми людьми; а совсем недавно я читала лекцию в библиотеке для большой аудитории людей и была на седьмом небе от счастья! И мой недавний отпуск, который я провела с мамой и папой, был очень счастливым.</p>
	</div> :
	state.activeItem === 'Саша' ? 
	<div className="ui bottom attached tab segment active" data-tab="sixth">
	<p>Марию я знала уже очень давно, но, как это бывает, стеснялась обратиться к ней, знакомой, со своими "пустяковыми" проблемами. Пока, извините, не дошла до ручки. Я пережила сильнейший стресс во время беременности, когда мне ставили страшнейшие диагнозы, говорили, что ребенка своего я не увижу, и, скорее всего, не выживу сама. Я родила здоровую дочку, но накопленный стресс усугублялся недосыпом, усталостью, учащающимися ссорами с любимым мужем, который стал казаться совсем чужим. </p>

	<p>У меня начались кошмары, я испытала на себе что значит "навязчивые идеи", о самой жуткой из которых я рассказала Марии во время нашего первого спонтанного сеанса. Мне уже было нечего терять, хотя я очень боялась ее шокировать, но она абсолютно спокойно меня выслушала — с этого и началось мое "излечение". </p>

	<p>Хочу сказать, что до этого я обращалась к трем терапевтам, которые, безусловно, частично мне помогли, но это было, знаете, как лечить рану поверхностно, и вроде как становится лучше, не так заметно, но внутрь лечение просто не доходит, источник боли и страданий остается на месте.</p>

	<p>Мария решает проблемы глобально. Разматывает гигантский клубок, от которого раз — и ничего не остается. Я хожу на консультации сама и с мужем. Оба вида работы одинаково полезны. Недавно причиной визита стал страх у нашей маленькой дочки, из-за которого она стала плаксивой и тревожной, даже играть перестала. И знаете что? Решение, которое Мария подсказала в первые же минуты разговора, сработало! Малышка в тот же вечер снова достала из сундука свои игрушки!!!</p>

	<p>Подсела ли я? Несомненно! Но встречи наши не случаются чаще, чем раз в 1-2-3 месяца, считаю эту периодичность вполне закономерной. Мне спокойно от того, что что бы ни случилось, я всегда могу на нее рассчитывать.</p>
	</div>: 
	state.activeItem === 'Аня' ? 
	<div className="ui bottom attached tab segment active" data-tab="sixth">
	<p>Пару тройку лет вы помогли моей маме справиться с паническими атаками, страхом выходить на улицу и идти и на работу. Причём результат был виден быстро.</p> 
	<p>Не поблагодарила Вас, простите. А сейчас вдруг вспомнила и поняла, что это надо сделать обязательно))</p>
<p>Это уже не первый случай, когда Вы мне/нам помогаете. Лет 8 назад обращалась к Вам с проблемой, касающейся дочки Евы, тогда ей было года 2. </p>
<p>Спасибо <span role='img' aria-label="hug emoji">🤗</span></p>
</div> : null
}
</div>
<hr/>
</section>
)
}

export default Feedback
